.navbar {
  background-color: rgba(206, 222, 189, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  backdrop-filter: blur(20px);
}

.navbar-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100vh;
  width: 200px;
  z-index: 999;
  background-color: rgba(206, 222, 189, 0.6);
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: right 0.75s ease-in-out;
}

.sidebar.active {
  right: 0;
}

.sidebar li {
  width: 100%;
}

.sidebar a {
  width: 100%;
}

/* Desktop */
@media (min-width: 768px) {
  .nav-link {
    color: rgb(0, 0, 0);
    text-decoration: none;
    border-radius: 20px;
    padding: 5px;
  }

  .hideOnDesktop {
    display: none;
  }

  .hideOnMobile {
    display: flex;
    margin-right: 10px;
    padding: 15px;
    text-align: center;
  }
}

/* Mobile */
@media (max-width: 767px) {

  /* .hideOnDesktop {
    background-color: rgba(206, 222, 189, 0.6);
    backdrop-filter: blur(5px);
    width: 100%;
    text-align: right;
  } */

  .navbar {
    background-color: rgba(206, 222, 189, 0);
    backdrop-filter: blur(0);
  }
  
  .navbar-nav {
    justify-content: flex-end;
  }

  .hideOnMobile {
    display: none;
  }

  .nav-link {
    color: rgb(0, 0, 0);
    text-decoration: none;
    border-radius: 20px;
    padding: 5px;
  }

  .sidebar {
    width: 35%;
  }
  
  .nav-item {
    margin-right: 10px;
    padding: 15px;
    text-align: left;
    display: ruby;
    margin-left: -35px;
  }

  .nav-item-2:hover{
    background-color: rgba(255, 255, 255, 0);
  }

  .nav-link2:hover{
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    padding-top: 25px;
    padding-right: 2px;
  }
}

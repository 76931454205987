.welcome-spacing{
    padding-top: 10%;
}
.contact-links {
    margin-bottom: -1px;
    margin-top: 15px;
  }
  
  .highlight {
    color: rgb(158, 179, 132);
  }
  
  .highlight:hover {
    color: #cedebd;
  }
  
  .jira-svg, .mysql-svg {
    height: 24px;
    width: 24px;
  }
  
  .main {
    min-height: 100vh;
  }
  
  .page-bottom{
    margin-top: 100px;
  }
  
  .profile-frame {
    border-radius: 50%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, .8);
    height: 350px;
    overflow: hidden;
    width: 350px;
  }
  
  .profile-image {
    display: block;
    height: auto;
    width: 116%;
    margin: -31px
  }
  
  .profile-image.contact {
    display: block;
    height: auto;
    margin: -66px;
    width: 130%;
  }
  
  .profile-image:hover {
    content: 'Test';
  }
  
  .project-image {
    height: auto;
    width: 200px;
  }
  .project-image:hover {
    height: auto;
    width: 200px;
    scale: 1.04;
  }
  
  .skill {
    display: flex;
    flex-direction: row;
  }
  
  .skill.tools {
    display: flex;
    flex-direction: row;
    margin-left: 70px;
  }
  
  .welcome-name {
    display: inline-table;
  }
  
  .h2-tools {
    font-size: 36px;
    margin-left: 25px;
  }

  .p.welcome{
    margin: -40px;
  }

  .ul-tools{
    margin-right: 12px;
  }

  #welcome {
    padding-top: 100px;
  }

    /* Desktop */
@media (min-width: 768px) {
  
    .paragraph{
      width: 45%;
      text-align: center;
    }
  
    #welcome{
      margin-top: -43px;
    }
  
  }
  
  /* Mobile */
  @media (max-width: 767px) {
  
    .paragraph{
      width: 80%;
      text-align: center;
    }
  
    #welcome{
      margin-top: -70px;
    }
  }
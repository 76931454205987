.container {
    padding: 2rem;
  }
  
  .slider-wrapper {
    position: relative;
    max-width: 48rem;
    margin: 0 auto;
  }
  
  .slider {
    display: flex;
    aspect-ratio: 16 / 9;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    box-shadow: 0 1.5rem 3rem -0.75rem hsla(100, 0%, 0%, 0.25);
    border-radius: 0.25rem;
    scrollbar-width: none;
  }
  
  .slider img {
    flex: 1 0 100%;
    height: auto;
    scroll-snap-align: start;
    object-fit: cover;
  }
  
  .slider-nav {
    display: flex;
    column-gap: 1rem;
    position: absolute;
    bottom: 1.25rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  
  .slider-nav button {
    background: none;
    border: none;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    opacity: 0.75;
    transition: opacity ease 250ms;
    cursor: pointer;
  }
  
  .slider-nav button:hover,
  .slider-nav button:focus-visible{
    opacity: 1;
    scale: 1.1;
  }
  
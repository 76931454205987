body {
  font-family: "Source Sans 3", sans-serif;
  background-color: rgb(250, 241, 228);
}

  /* Anchor Tags */
  a {
    color: black;
  }
  
  a:visited {
    color: black;
  }
  
  a:hover{
    color: rgb(158, 179, 132);
  }
  
  /* Elements */
  
  h2 {
    font-size: 36px;
  }
  
  p {
    font-size: 24px;
    line-height: 1.5;
  }
  
  ul {
    margin: auto;
  }

  li{
    list-style: none;
}
  
  /* Section */
  section {
    align-content: center;
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    place-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  /* Desktop */
@media (min-width: 768px) {
  h1 {
    font-size: 48px;
    margin-bottom: 45px;
  }

}

/* Mobile */
@media (max-width: 767px) {
  h1 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 45px;
  }
}
.div.dragon{
    display: flex;
    justify-content: center;
}

@keyframes rotateBorderColor {
    0% {
        border-color: rgb(158, 179, 132);
    }
    25% {
        border-color: rgb(200, 100, 100);
    }
    50% {
        border-color: rgb(100, 200, 150);
    }
    75% {
        border-color: rgb(150, 100, 200);
    }
    100% {
        border-color: rgb(158, 179, 132);
    }
}

.back-button:hover{
    scale: 1.05;
}

.back-button{
    right: 30px;
    position: fixed;
    padding-left: 30px;
    padding-right: 30px;
    background-color: rgb(250, 241, 228);
    border-color: rgb(158, 179, 132);
    border-radius: 10px;
    cursor: pointer;
    z-index: 1;
    animation: rotateBorderColor 10s linear infinite;
}

.dragon-div{
    display: flex;
    flex-direction: column;
    align-items: center;
}

iframe{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dragtools{
    list-style: initial;
    font-size: 24px;
}

.page-project-image{
    height: auto;
    width: 200px;
}

.paragraph-drag{
    text-align: center;
    width: 55%;
}

/* Desktop */
@media (min-width: 768px) {
    .paragraph-drag {
        text-align: center;
        width: 55%;
    }

    .dragon-div2{
        margin-top: 150px;
        margin-bottom: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

/* Mobile */
@media (max-width: 767px) {
    h1{
        padding-top: 12px;
    }
    .paragraph-drag{
        margin-top: -5px;
        text-align:center;
        width: 80%;
    }

    .sectionD{
        align-content: center;
        display: flex;
        flex-direction: column;
        min-height: 55vh;
        place-items: center;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .dragon-div2{
        margin-top: 50px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}